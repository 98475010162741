import { createContext } from "react";
import { CounsellingOption } from "../types/response";

interface JeeContextType {    
    counselling: {
        options: CounsellingOption[];
        isLoading: boolean;
        selectedId: number;
        year: number;
    }
}

export const JeeContext = createContext<JeeContextType>({
    counselling: {
        options: [],
        isLoading: false,
        selectedId: 1,
        year: 2023,
    }
});
