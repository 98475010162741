import { Link } from "@mui/material";

interface Props {
    sourceName: string;
    sourceLink: string;
}
const JeeTableFooter: React.FC<Props> = ({sourceName, sourceLink}) => {
    return <span>Source: <Link href={sourceLink} underline='hover'>{sourceName}</Link></span>;
}

export default JeeTableFooter;
