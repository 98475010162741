import { Box } from '@mui/material'
import React, { PropsWithChildren, ReactNode } from 'react'

interface Props extends PropsWithChildren {
  filterIcon?: ReactNode;
}
const CustomTableHeader: React.FC<Props> = ({children, filterIcon}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ px: 4, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {children}
      </Box>
      <Box sx={{ px: 4, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {filterIcon && filterIcon}
      </Box>
    </Box>
  )
}

export default CustomTableHeader;