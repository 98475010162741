import { PaginationState, SortingState } from "@tanstack/react-table";
import { CustomFilterData } from "./components/filter/CustomFilter";

export const addFilter = (url: string, filters: CustomFilterData) => {
    Object.keys(filters).forEach(k => {
        if (filters[k]) {
            url += `${k}=${filters[k].val}&`
        }
    });
    return url;
}

export const addSort = (url: string, sorting: SortingState) => {
    if (sorting.length > 0) {
        url += 'sort='
    }
    sorting.forEach(val => url += `${val.id}:${val.desc ? "desc" : "asc"}&`);
    return url;
}

export const addPagination = (url: string, pagination: PaginationState) => {
    return `${url}limit=${pagination.pageSize}&page=${pagination.pageIndex+1}`;
}