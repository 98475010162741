import { useMutation } from "@tanstack/react-query";
import config from "../../config";

const useAddSuggestion = () => {
    const addSuggestion = async (suggestion: string): Promise<string> => {
        const suggestionJson = { suggestion };

        const response = await fetch(`${config.apiUrl}/suggestion`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(suggestionJson),
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            throw new Error(errorMessage || 'Failed to add suggestion');
        }

        return response.json();
    };

    const mutation = useMutation({mutationFn: addSuggestion});
    return mutation;
};

export default useAddSuggestion;
