import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import useAddSuggestion from "./query/useAddSuggestion";

interface Props{}
const Suggestion: React.FC<Props> = () => {
    const [suggestion, setSuggestion] = useState("");
    const mutation = useAddSuggestion();

    const handleAddSuggestion = async () => {
        await mutation.mutate(suggestion);
        alert("Suggestion submitted");
        setSuggestion("");
    }

    return (
        <div style={{ padding: '20px', maxWidth: '500px', margin: 'auto' }}>
            <TextField
                placeholder="Type your suggestion here..."
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                value={suggestion}
                onChange={(e) => setSuggestion(e.target.value)}
                sx={{ marginBottom: '16px' }}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleAddSuggestion}
                fullWidth
            >
                Submit
            </Button>
        </div>
    )
}

export default Suggestion;
