import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { FetchDataResponse } from "../../common/types";
import config from "../../config";
import { JeeContext } from "../context/jee";

const useGetRounds = (): [FetchDataResponse<number> | undefined, boolean] => {
    const {counselling} = useContext(JeeContext);

    const fetchRounds = async (): Promise<FetchDataResponse<number>> => {
        try {
            // const username = "nitin";
            // const password = "nitin";
            const headers = new Headers();

            // headers.set("Authorization", `Basic ${btoa(`${username}:${password}`)}`);
            headers.set("Content-Type", "application/json");
            const counsellingName = counselling.options.find(option => option.id === counselling.selectedId)?.name;
            const response = await fetch(`${config.apiUrl}/jee/${counsellingName}/round?year=${counselling.year}`, {method: 'GET', headers});
            if (!response.ok) {
              return {
                rows: [],
              }
            }
            const data = await response.json();
            return {
                rows: data.rows,
            }
        } catch (err) {
            return Promise.reject(err);
        }
    }
    const {data, isFetching, isLoading} = useQuery({
        queryKey: [
          'josaa-round',
          counselling.selectedId,
        ],
        enabled: !counselling.isLoading,
        queryFn: fetchRounds,
          // placeholderData: keepPreviousData,
      });

    return [data, isLoading && isFetching];
}

export default useGetRounds;