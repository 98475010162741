import { Tune } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react'

interface Props {
  openFilterDialog: () => void
}

const JeeFilterIcon: React.FC<Props> = ({openFilterDialog}) => {
  return (
    <IconButton onClick={openFilterDialog}>
        <Tune />
    </IconButton>
  )
}

export default JeeFilterIcon;