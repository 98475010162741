import { Box, Typography } from '@mui/material'
import React from 'react'

interface Props {
    title: string;
}
const CustomPageHeader: React.FC<Props> = ({title}) => {
  return (
    <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="h4" component="h1">
        {title}
        </Typography>
    </Box>
  )
}

export default CustomPageHeader;