import { useQuery } from "@tanstack/react-query";
import { FetchDataResponse } from "../../common/types";
import config from "../../config";
import { Quota } from "../types/response";

const useQuotas = (): [FetchDataResponse<Quota> | undefined, boolean] => {
    const fetchQuotas = async (): Promise<FetchDataResponse<Quota>> => {
        try {
            // const username = "nitin";
            // const password = "nitin";
            const headers = new Headers();

            // headers.set("Authorization", `Basic ${btoa(`${username}:${password}`)}`);
            headers.set("Content-Type", "application/json");
            const response = await fetch(`${config.apiUrl}/jee/quota`, {method: 'GET', headers});
            if (!response.ok) {
              return {
                rows: [],
              }
            }
            const data = await response.json();
            return {
                rows: data.rows,
            }
        } catch (err) {
            return Promise.reject(err);
        }
    }

    const {data, isFetching, isLoading} = useQuery({
        queryKey: [
          'josaa-quota',
        ],
        queryFn: fetchQuotas,
          // placeholderData: keepPreviousData,
      });
      return [data, isLoading && isFetching];
}

export default useQuotas;