import { InputLabel, Select, MenuItem, SelectChangeEvent, CircularProgress } from '@mui/material';
import React, { useContext } from 'react'
import CustomTableHeader from '../common/components/table/tableHeader/CustomTableHeader';
import { JeeContext } from './context/jee';
import JeeFilterIcon from './JEEFilterIcon';
import useGetRounds from './query/useGetRounds';

interface Props {
    openFilterDialog: () => void;
    year: number;
    setYear: React.Dispatch<React.SetStateAction<number>>;
    round: number;
    setRound : React.Dispatch<React.SetStateAction<number>>;
    counsellingOption: number;
    setCounsellingOption: React.Dispatch<React.SetStateAction<number>>;
}
const JeeTableHeader: React.FC<Props> = ({openFilterDialog, year, setYear, round, setRound, counsellingOption, setCounsellingOption}) => {
    const {counselling} = useContext(JeeContext)
    const [rounds, isRoundsLoading] = useGetRounds();

    const handleYearChange = (e: SelectChangeEvent<number>) => {
        setYear(e.target.value as number);
    }

    const handleCounsellingChange = (e: SelectChangeEvent<number>) => {
        setCounsellingOption(e.target.value as number);
    }

    const handleRoundChange = (e: SelectChangeEvent<number>) => {
        setRound(e.target.value as number);
    }

  return (
    <CustomTableHeader filterIcon={<JeeFilterIcon openFilterDialog={openFilterDialog} />}>
        <InputLabel htmlFor="year" sx={{marginRight: 1}}>Year: </InputLabel>
        <Select
            variant="standard"
            id="year"
            name="year"
            value={year}
            onChange={(e) => handleYearChange(e)}
            multiple={false}
        >
            {[2023].map((option) => (
            <MenuItem key={option} value={option}>
                {option}
            </MenuItem>
            ))}
        </Select>
        <InputLabel htmlFor="counselling" sx={{marginLeft: 2, marginRight: 1}}>Counselling: </InputLabel>
        <Select
            variant="standard"
            id="counselling"
            name="counselling"
            value={counsellingOption}
            onChange={(e) => handleCounsellingChange(e)}
            multiple={false}
        >
            {counselling.isLoading ? <CircularProgress /> : counselling.options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
                {option.name.toUpperCase()}
            </MenuItem>
            ))}
        </Select>
        <InputLabel htmlFor="round" sx={{marginLeft: 2, marginRight: 1}}>Round: </InputLabel>
        <Select
            variant="standard"
            id="round"
            name="round"
            value={round}
            onChange={(e) => handleRoundChange(e)}
            multiple={false}
        >
            {isRoundsLoading ? <CircularProgress /> : rounds?.rows.map((round) => (
            <MenuItem key={round} value={round}>
                {round}
            </MenuItem>
            ))}
        </Select>
    </CustomTableHeader>
  )
}

export default JeeTableHeader;