import { Column, ColumnDef, PaginationState, SortingState } from "@tanstack/react-table";
import { useContext, useMemo } from "react";
import CustomTable from "../common/components/table/CustomTable";
import { CSAB_SOURCE_LINK, JOSAA_SOURCE_LINK } from "./constants";
import { JeeContext } from "./context/jee";
import JeeTableFooter from "./JEETableFooter";
import { FetchJosaaDataResponse, Josaa } from "./types/response";

interface Props {
    data?: FetchJosaaDataResponse;
    isLoading: boolean;
    pagination: PaginationState;
    setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
    sorting: SortingState;
    setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
}
const JeeTable: React.FC<Props> = ({
    data,
    isLoading,
    pagination,
    setPagination,
    sorting,
    setSorting,
}) => {
  const {counselling} = useContext(JeeContext);
    const renderSortHeader = (col: Column<Josaa, unknown>, header: string) => (
      <div onClick={col.getToggleSortingHandler()}>
        <span>
          {`${header} `}
          {col.getIsSorted() === "asc"
            ? '↑'
            : col.getIsSorted() === "desc"
            ? '↓'
            : ' '}
          </span>
      </div>
    );

    const columns = useMemo<ColumnDef<FetchJosaaDataResponse["rows"][0]>[]>(
        () => [
          {
            accessorKey: 'institute.name',
            id: 'institute',
            header: ({ column }) => renderSortHeader(column, 'Institute'),
            cell: info => info.getValue(),
            filterFn: 'includesString',
            size: 150,
          },
          {
            accessorKey: 'academic_program.name',
            id: 'program',
            header: ({ column }) => renderSortHeader(column, 'Academic Program'),
            filterFn: 'includesString',
            size: 200,
          },
          {
            accessorKey: 'quota.name',
            id: 'quota',
            header: ({ column }) => renderSortHeader(column, 'Quota'),
            filterFn: 'includesString',
            size: 30,
          },
          {
            accessorKey: 'seat_type.name',
            id: 'seat',
            header: ({ column }) => renderSortHeader(column, 'Seat Type'),
            filterFn: 'includesString',
            size: 30,
          },
          {
            accessorKey: 'gender.name',
            id: 'gender',
            header: ({ column }) => renderSortHeader(column, 'Gender'),
            filterFn: 'includesString',
            size: 60,
          },
          {
            accessorFn: row => `${row.opening_rank}${row.is_opening_preparatory ? "P" : ""}`,
            id: 'openingRank',
            header: ({ column }) => renderSortHeader(column, 'Opening Rank'),
            filterFn: 'inNumberRange',
            size: 40,
          },
          {
            accessorFn: row => `${row.closing_rank}${row.is_closing_preparatory ? "P" : ""}`,
            id: 'closingRank',
            header: ({ column }) => renderSortHeader(column, 'Closing Rank'),
            filterFn: 'inNumberRange',
            size: 40,
          },
          // {
          //   accessorKey: 'institute.nirf_rank',
          //   header: 'NIRF Rank',
          //   filterFn: 'inNumberRange',
          // },
        ],
        []
      );

      const sourceName = counselling.options.find(option => option.id === counselling.selectedId)?.name.toUpperCase() ?? "";
      const sourceLink = counselling.selectedId === 1 ? JOSAA_SOURCE_LINK : CSAB_SOURCE_LINK;

    return (
      <CustomTable
          data={data}
          isLoading={isLoading}
          columns={columns}
          pagination={pagination}
          setPagination={setPagination}
          sorting={sorting}
          setSorting={setSorting}
          footer={<JeeTableFooter sourceName={sourceName} sourceLink={sourceLink} />}
      />
    )
}

export default JeeTable;