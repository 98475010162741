import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, SelectChangeEvent, TextField } from "@mui/material";
import React, { ChangeEvent } from "react";
import { DropdownOption } from "../../types";

interface TextFormField {
    type: "text";
    val: string;
}
interface NumericFormField {
    type: "numeric";
    val: number;
}
interface SingleDropDownFormField {
    type: "dropdown";
    options: DropdownOption[];
    multi: boolean;
    val: string;
}
interface MultiDropDownFormField {
  type: "dropdown";
  options: DropdownOption[];
  multi: boolean;
  val: string[];
}
export type CustomFormField = {
    name: string;
    label: string;
} & (TextFormField | NumericFormField | SingleDropDownFormField | MultiDropDownFormField);
export type CustomFilterData = {[name: string]: {val: CustomFormField["val"], error?: string}};

interface Props {
    isOpen: boolean;
    close: () => void;
    filterDef: CustomFormField[];
    onSubmit: (filters?: CustomFilterData) => void;
    filters: CustomFilterData;
    localFilters: CustomFilterData;
    setLocalFilters: React.Dispatch<React.SetStateAction<CustomFilterData>>;
    extraHandler: (fieldName: string, fieldVal: CustomFormField["val"]) => void;
}

const CustomFilter: React.FC<Props> = ({isOpen, close, onSubmit, filterDef, filters, localFilters, setLocalFilters, extraHandler}) => {
    const handleTextInputChange = (
        event: ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>,
        fieldName: string
      ): void => {
        const { value } = event.target;
        setLocalFilters((prev) => ({
          ...prev,
          [fieldName]: {val: value as string},
        }));
        extraHandler(fieldName, value as string);
      };

      const handleSingleSelectChange = (
        event: SelectChangeEvent<string>,
        fieldName: string
      ): void => {
        const { value } = event.target;
        setLocalFilters((prev) => ({
          ...prev,
          [fieldName]: {val: value},
        }));
        extraHandler(fieldName, value);
      };

      const handleMultiSelectChange = (
        event: SelectChangeEvent<string[]>,
        fieldName: string
      ): void => {
        const { value } = event.target;
        setLocalFilters((prev) => ({
          ...prev,
          [fieldName]: {val: value},
        }));
        extraHandler(fieldName, value);
      };

      const handleNumericChange = (
        event: ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>,
        fieldName: string
      ): void => {
        const { value } = event.target;
        setLocalFilters((prev) => ({
          ...prev,
          [fieldName]: {val: value as number},
        }));
        extraHandler(fieldName, value as number);
      };

    const handleSubmit = (): void => {
        onSubmit();
        close();
    };

    const clearAllFilters = () => {
        setLocalFilters({});
        onSubmit({});
        close();
    }

    const onCancel = () => {
        setLocalFilters(filters);
        close();
    }

    const renderFormField = (field: CustomFormField) => {
        switch(field.type) {
            case "text":
                return (
                    <TextField
                        key={field.name}
                        label={field.label}
                        name={field.name}
                        value={localFilters[field.name].val ?? ''}
                        onChange={(e) => handleTextInputChange(e, field.name)}
                        fullWidth
                    />
                )
                case "numeric":
                    return (
                      <TextField
                        key={field.name}
                        label={`${field.label}`}
                        type="number"
                        value={(localFilters[field.name]?.val as string)?.split("-")[0] ?? ''}
                        onChange={(e) => handleNumericChange(e, field.name)}
                        helperText={localFilters[field.name]?.error}
                      />
                    );
            case "dropdown":
                return (
                  <Autocomplete
                    key={field.name}
                    multiple={field.multi}
                    options={field.options}
                    getOptionLabel={(option) => option?.key}
                    value={
                      field.multi
                        ? (localFilters[field.name]?.val as string[])?.map(
                            (val) => field.options.find((opt) => opt.val === val)!
                          ) || []
                        : field.options.find((opt) => opt.val === localFilters[field.name]?.val) || null
                    }
                    onChange={(event, newValue) => {
                      if (field.multi) {
                        handleMultiSelectChange(
                          {
                            target: {
                              value: (newValue as DropdownOption<string>[])?.map((opt) => opt.val),
                            },
                          } as SelectChangeEvent<string[]>,
                          field.name
                        );
                      } else {
                        handleSingleSelectChange(
                          {
                            target: {
                              value: (newValue as DropdownOption<string>)?.val || "",
                            },
                          } as SelectChangeEvent<string>,
                          field.name
                        );
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.val === (value as DropdownOption<string>)?.val
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={field.label}
                        placeholder={field.multi ? "Select multiple..." : "Select..."}
                      />
                    )}
                  />
              );
            default:
                return null;
        }
    }

    return (
        <div>
            <Dialog fullWidth open={isOpen} onClose={close}>
                <DialogTitle>Filter</DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{display: "flex", flexDirection: "column", gap: 2}}>
                        {filterDef.map(field => renderFormField(field))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="warning" onClick={onCancel}>Cancel</Button>
                    <Button onClick={clearAllFilters}>Clear All filters</Button>
                    <Button variant="contained" onClick={handleSubmit}>Search</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default CustomFilter;