import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import CustomAppBar from './common/components/appBar/CustomAppBar';
import JEE from './jee/JEEPage';
import Suggestion from './suggestion/Suggestion';

function App() {

  return (
    <div className="App">
      <CustomAppBar />
        <Routes>
          <Route path='/' element={<Navigate to="/jee" replace={true} />} />
          <Route path='/jee' element={<JEE />} />
          <Route path='/suggest' element={<Suggestion />} />
          {/* <Route path="*" element={<NotFoundPage />} /> */}
        </Routes>
    </div>
  );
}

export default App;
