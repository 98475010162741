import { TableRow, TableCell, Skeleton } from "@mui/material";
import React from "react";

interface Props {
    rows?: number;
    cols?: number;
}
const LoadingTable: React.FC<Props> = ({rows=10, cols=5}) => {
    return (
        <>
          {Array.from(new Array(rows)).map((_, rowIndex) => (
            <TableRow key={rowIndex}>
              {Array.from(new Array(cols)).map((_, colIndex) => (
                <TableCell key={colIndex}>
                  <Skeleton animation="wave" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </>
      );
}

export default LoadingTable;