import { useEffect, useMemo, useState } from "react";
import CustomFilter, { CustomFilterData, CustomFormField } from "../common/components/filter/CustomFilter"
import { DropdownOption } from "../common/types";
import useAcademicPrograms from "./query/useAcademicPrograms";
import useGenders from "./query/useGenders";
import useGetInstitutes from "./query/useGetInstitutes";
import useGetInstituteTypes from "./query/useGetInstituteTypes";
import useQuotas from "./query/useQuotas";
import useSeatTypes from "./query/useSeatType";

interface Props {
    isOpen: boolean
    close: () => void
    filters: CustomFilterData;
    setFilters: React.Dispatch<React.SetStateAction<CustomFilterData>>;
}

const JeeFilter: React.FC<Props> = ({isOpen, close, filters, setFilters}) => {
  const [localFilters, setLocalFilters] = useState<CustomFilterData>(filters);
  
  // TODO: Remove IIT for CSAB
  const [instituteTypes] = useGetInstituteTypes();
  const instituteTypeOptions = useMemo<DropdownOption[]>(() => !instituteTypes ? [] : instituteTypes.rows.map(instituteType => ({
    key: instituteType.name,
    val: instituteType.id.toString(),
  })), [instituteTypes]);
  const locallySelectedInstituteType = (localFilters["instituteType"]?.val as string[]);

  const [institutes] = useGetInstitutes();
  const instituteOptions = useMemo<DropdownOption[]>(() => institutes?.rows
    .filter(institute => locallySelectedInstituteType?.length ? locallySelectedInstituteType.includes(institute.type.toString()) : true)
    .map(institute => ({
      key: institute.name,
      val: institute.id.toString(),
    })
  ) ?? [], [institutes, locallySelectedInstituteType]);

  const [academicPrograms] = useAcademicPrograms();
  const academicProgramOptions = useMemo<DropdownOption[]>(() => academicPrograms?.rows.map(program => ({
    key: program.name,
    val: program.id.toString(),
  })) ?? [], [academicPrograms]);

  const [quotas] = useQuotas();
  const quotaOptions = useMemo<DropdownOption[]>(() => quotas?.rows.map(quota => ({
    key: quota.name,
    val: quota.id.toString(),
  })) ?? [], [quotas]);

  const [seatTypes] = useSeatTypes();
  const seatTypeOptions = useMemo<DropdownOption[]>(() => seatTypes?.rows.map(seatType => ({
    key: seatType.name,
    val: seatType.id.toString(),
  })) ?? [], [seatTypes]);

  const [genders] = useGenders();
  const genderOptions = useMemo<DropdownOption[]>(() => genders?.rows.map(gender => ({
    key: gender.name,
    val: gender.id.toString(),
  })) ?? [], [genders]);

  const filterDef = useMemo<CustomFormField[]>(() => [
    {
      type: "dropdown",
      name: "instituteType",
      label: "Institute Type",
      options: instituteTypeOptions,
      val: [],
      multi: true,
    },
    {
      type: "dropdown",
      name: "institute",
      label: "Institute",
      options: instituteOptions,
      val: [],
      multi: true,
    },
    {
      type: "dropdown",
      name: "program",
      label: "Academic Program",
      options: academicProgramOptions,
      val: [],
      multi: true,
    },
    {
      type: "dropdown",
      name: "quota",
      label: "Quota",
      options: quotaOptions,
      val: "",
      multi: false,
    },
    {
      type: "dropdown",
      name: "seat",
      label: "Seat Type",
      options: seatTypeOptions,
      val: "",
      multi: false,
    },
    {
      type: "dropdown",
      name: "gender",
      label: "Gender",
      options: genderOptions,
      val: "",
      multi: false,
    },
    {
      type: "numeric",
      name: "openingRank",
      label: "Opening Rank",
      val: 0,
    },
    {
      type: "numeric",
      name: "closingRank",
      label: "Closing Rank",
      val: 0,
    },
  ], [instituteOptions, instituteTypeOptions, academicProgramOptions, quotaOptions, seatTypeOptions, genderOptions]);

  useEffect(() => {
    setFilters((prev) => {
      const {institute, ...rest} = prev;
      return rest;
    });
  }, [locallySelectedInstituteType, setFilters]);

  const onSubmit = (filters?: CustomFilterData) => {
    setFilters(filters ?? localFilters);
  }

  const extraHandler = (fieldName: string, fieldVal: CustomFormField["val"]) => {
    if (fieldName === "openingRank") {
      const closingRank = Number(localFilters["closingRank"]?.val);
      let err = "";
      if (Number(fieldVal) > closingRank) {
        err = "Opening rank should be less than closing rank";
      }
      setLocalFilters(prev => ({
        ...prev,
        "openingRank": {...prev["openingRank"], error: err},
        "closingRank": {...prev["closingRank"], error: ""},
      }));
    }
    if (fieldName === "closingRank") {
      const openingRank = Number(localFilters["openingRank"]?.val);
      let err = "";
      if (openingRank > Number(fieldVal)) {
        err = "Closing rank should be more than opening rank";
      }
      setLocalFilters(prev => ({
        ...prev,
        "closingRank": {...prev["closingRank"], error: err},
        "openingRank": {...prev["openingRank"], error: ""},
      }));
    }
  }

  return (
    <CustomFilter
        isOpen={isOpen}
        close={close}
        filterDef={filterDef}
        filters={filters}
        localFilters={localFilters}
        setLocalFilters={setLocalFilters}
        onSubmit={onSubmit}
        extraHandler={extraHandler}
    />
  )
}

export default JeeFilter;